// default smallest
.authbox {
  max-width: 100vw !important;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  // max-height: 100vh;
  border-radius: 0.5rem;
  overflow: hidden;
}

@media screen and (min-width: 508px) {
  .authbox {
    max-width: 500px !important;
    margin-left: auto;
    margin-right: auto;
    // max-height: 50vh;
  }
}

.authstatus {
  border-radius: 0.25rem;
  // color: #a00;
  font-weight: 200;
  background-color: transparent;
  animation: warnfade 1s ease-in-out;
  /* transitions aren't working--will work on later, not important now */
  &.visible {
    height: auto;
    transition: height 1s linear;
  }
  &.hidden {
    height: 0;
    transition: height 1s linear;
  }
}

.tabs {
  -webkit-tap-highlight-color: transparent;
  outline: false;
}
.tabList {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.tab {
  list-style: none;
  flex: 1;
  white-space: nowrap;
  font-weight: bold;
  position: relative;
  text-align: center;
  padding: 0.75rem 0.75rem;
  cursor: pointer;
  font-size: 1.25rem;
  &.selected {
    background: transparent;
    backdrop-filter: none;
  }
}
