@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,200;0,400;0,600;0,700;0,900;1,200;1,400;1,600;1,700;1,900&display=swap');
:root {
  --primary1: #65b3ff;
  --primary2: #378bcf;
  --primary3: #225284;
  --gray1: #efefef;
  --gray2: #ccc;
  --gray3: #aaa;
  --gray4: #777;
  --gray5: #444;
  --gray6: #444;
  --text-white: white;
  --darkbg-font: #eef;
  --font-normal: 12pt;
  --font-doc: 12pt;
  --font-s: 10pt;
  --font-xs: 8pt;
  --font-xxs: 6pt;
  --font-l: 14pt;
  --font-xl: 18pt;
  --font-xxl: 24pt;
}
// basic functionality/defaults
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:focus {
  outline: 0;
  box-shadow: none;
}
html,
body {
  height: 100%;
  font-family: Exo, arial, helvetica, sans-serif;
  background: #000 url('/codex/assets/enr/spacebkgrd5.png');
  overscroll-behavior: none none;
  font-weight: 400;
  font-size: var(--font-normal);
  margin: 0;
  padding: 0;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
a {
  text-decoration: none;
  color: var(--primary3);
  &:hover {
    color: var(--primary2);
  }
  &:visited {
    color: var(--primary3);
  }
}
b,
strong {
  font-weight: 600;
}
input,
textarea {
  outline: 0;
  border: var(--gray3) solid 1px;
  border-radius: 0.25rem;
  padding: 0.25rem;
  line-height: 1.5;
  &:focus {
    border: var(--primary2) solid 1px;
  }
}
button {
  border: none;
  background: var(--gray1);
  border-radius: 0.25rem;
  padding: 0.25rem 1rem 0.25rem 1rem;
  &:hover {
    background: var(--gray2);
  }
  &:active {
    background: var(--gray3);
    color: white;
  }
}
th {
  text-align: left;
}

// specific style variations
.content {
  flex: 1 0 auto;
  .main {
    height: 100%;
    padding: 0 1rem 0 1rem;
    position: relative;
    display: grid;
    grid-template-columns: [home] 11rem [header] minmax(auto, 100%);
    grid-template-rows: 1fr minmax(auto, 100%);
    grid-template-areas:
      'home header'
      'nav body';
    align-items: start;
    align-content: start;
    .home {
      height: 1px;
      grid-area: home;
      .icon {
        position: absolute;
        top: 1.8rem;
        left: 1rem;
        img {
          width: 11rem;
        }
      }
    }
    .header {
      grid-area: header;
      padding: 1rem 0.25rem 1rem 0.25rem;
      z-index: +1;
      .title {
        border-top: solid 2px var(--primary3);
        width: calc(80%);
        font-size: var(--font-xxl);
        font-weight: 600;
        text-align: center;
        .content {
          text-overflow: ellipsis;
          overflow: hidden;
          width: calc(100%);
          white-space: nowrap;
        }
      }
      .bar1 {
        position: absolute;
        top: 69px;
        border-top: solid 2px var(--primary3);
        left: 0;
        right: 0;
      }
    }
    .nav {
      // position: fixed;
      // top: 5rem;
      z-index: +1;
      align-self: start;
      margin-right: 1rem;
      grid-area: nav;
      .bar {
        border-top: solid 2px var(--primary3);
        border-bottom: solid 2px var(--primary3);
        margin-top: 2rem;
        padding: 0.5rem 1rem 0.5rem 0;
        font-size: var(--font-s);
        font-weight: 400;
        margin-bottom: 2rem;
        padding: 0.125rem 0 0 0;
        a {
          color: white;
        }
        .item {
          white-space: nowrap;
          font-weight: 400;
          // &:not(.indent) a::before {
          //   content: '▹';
          //   padding-right: 4px;
          // }
          &.cat {
            font-weight: 600;
          }
          > a,
          > div {
            &::after {
              content: '|';
              padding-left: 3px;
              color: transparent;
            }
            padding: 0.25rem 0.5rem 0.25rem 0.5rem;
            display: block;
          }
          div {
            padding: 0.25rem 0.5rem 0.25rem 0.5rem;
            display: block;
          }
          &:hover > a,
          &:hover > div {
            &::after {
              color: inherit;
              animation: 1s blink ease-in-out infinite;
            }
            box-shadow: inset 0 0 20px rgba(0, 102, 204, 0.6),
              inset 0 0 80px rgba(0, 102, 204, 0);
          }
          .action {
          }
        }
      }
    }
    &.closed {
      grid-template-columns: [home] 0 [header] auto;
      .nav {
        position: absolute;
        margin-right: 0;
        left: -33px;
        .head {
          visibility: hidden;
        }
        .items {
          width: 0;
          left: 0;
        }
        .closed-bar {
          border-right: solid 2px var(--primary3);
          height: 20rem;
          min-height: 20rem;
        }
      }
      .header {
        margin-left: 11rem;
      }
    }
    .body {
      position: relative;
      width: 100%;
      height: 100%;
      grid-area: body;
      font-size: var(--font-normal);
      &.transparent {
        background: transparent;
      }
      .opine {
        z-index: +1;
        color: var(--darkbg-font);
        position: fixed;
        bottom: 1rem;
        right: 2rem;
        height: 15vh;
        width: 60vw;
        border: solid 1px var(--primary3);
        border-top: solid 2px var(--primary3);
        padding: 0;
        .background {
          height: 100%;
          background-color: rgba(0, 0, 0, 0.9);
          .dialog {
            padding: 0.5rem;
            height: 100%;
            font-size: var(--font-xs);
          }
        }
        &.closed {
          height: 0;
          bottom: 0;
        }
        .controls {
          top: -16px;
          right: -1px;
        }
        .handle {
          .quo {
            transform: rotate(-90deg);
          }
        }
        .opine-height {
          height: 15vh;
        }
      }
      .sidebar {
        z-index: +1;
        color: var(--darkbg-font);
        border: solid 1px var(--primary3);
        border-top: solid 2px var(--primary3);
        padding: 0;
        position: fixed;
        &.bottom-right {
          right: 2rem;
          bottom: 2rem;
          height: 30vh;
          width: 50vw;
          .scroller {
            padding: 0.5rem;
            height: calc(30vh - 1px);
          }
        }
        &.middle-right {
          right: 2rem;
          top: 50%;
          transform: translateY(-50%);
          height: 30vh;
          width: 50vw;
          .scroller {
            height: 30vh;
          }
        }
        .background {
          height: 100%;
          background-color: rgba(0, 0, 0, 0.9);
          .dialog {
            height: 100%;
            font-size: var(--font-xs);
          }
        }
        &.closed {
          height: 0;
          bottom: 0;
        }
        .controls {
          top: -16px;
          right: -1px;
        }
        .handle {
          .quo {
            transform: rotate(-90deg);
          }
        }
      }
    }
  }
}
.footer {
  flex-shrink: 0;
}
.bg-term-dark {
  position: relative;
  color: white;
  padding: 0.25em 0.25em 0.25em 0.25em;
  .controls {
    width: 100%;
    position: absolute;
    top: -18px;
    text-align: center;
    font-size: 7pt;
    display: flex;
    justify-content: space-between;
    &.intitle {
      font-weight: normal;
      top: -16px;
      right: 0;
      .head {
        border-top-right-radius: 0.25rem;
      }
    }
    .head {
      background: var(--primary3);
      border-top-left-radius: 1rem;
      max-width: 5rem;
      height: 1rem;
    }
    .handle {
      background: var(--primary3);
      border-top-right-radius: 0.25rem;
      min-width: 1rem;
      max-width: 1rem;
      height: 1rem;
      animation: 1s throb ease-in-out infinite;
    }
    &.action:hover .handle,
    &.action:hover .head {
      cursor: pointer;
      background-color: var(--primary2);
    }
  }
}
.main.closed .nav .bar {
  .controls .handle {
    /*
    position: fixed;
    left: 0;
    top: 7.5rem;
    */
    position: absolute;
    right: -6rem;
    max-width: 5rem;
    min-width: 5rem;
    border-bottom-right-radius: 0.25rem;
  }
  .closed-bar {
    z-index: -1;
    position: absolute;
    right: -21px;
    top: -0.5rem;
    border: solid 2px var(--primary3);
  }
}
.halo-inner {
  box-shadow: inset 0 0 40px rgba(0, 102, 204, 0.6),
    inset 0 0 160px rgba(0, 102, 204, 0.2);
  background-image: linear-gradient(
    0deg,
    rgba(0, 128, 255, 0.3),
    rgba(0, 0, 0, 0)
  );
}
.halo-inner-black {
  box-shadow: inset 0 0 40px rgba(0, 0, 0, 1), inset 0 0 160px rgba(0, 0, 0, 1);
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
.flex-center {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: center;
}
.primary1 {
  color: var(--primary1);
}
a.primary1:hover {
  color: white;
}
.primary2 {
  color: var(--primary1);
}
.primary3 {
  color: var(--primary1);
}
.b--primary1 {
  border-color: var(--primary1);
}
.b--primary2 {
  border-color: var(--primary1);
}
.b--primary3 {
  border-color: var(--primary1);
}
// have it hang a little
.text-outline {
  text-shadow: 2px 2px 3px black, -1px -1px 2px black, -1px 1px 0 black,
    1px 1px 0 black, 1px -1px 0 black;
}
.text-outline2 {
  text-shadow: 4px 4px 5px black, -2px -2px 0px black, -2px 2px 0 black,
    2px 2px 0 black, 2px -2px 0 black;
}
.hover-lightbg:hover {
  background-color: rgba(0, 0, 0, 0.25);
  color: #222;
}
.hover-redbg:hover {
  background-color: #ff4136;
  color: white;
}

// doc styles
.bg-term-light,
.content .main .body .doc {
  color: #222;
  background: #fff url('/codex/assets/enr/bg-gray.gif');
  overflow-y: hidden;
  padding-left: 2rem;
  padding-right: 2rem;
}

// keyframes
@keyframes blink {
  from,
  to {
    color: inherit;
  }
  50% {
    color: transparent;
  }
}
@keyframes throb {
  from,
  to {
    color: inherit;
  }
  50% {
    color: var(--primary1);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// other classes
.loading-inline {
  margin-left: 1em;
  width: 1em;
  height: 1em;
  border-radius: 3em;
  animation: spin 1s linear infinite;
  border-top: 0.5em solid var(--primary3);
  border-right: 0.5em solid var(--primary3);
  border-bottom: 0.5em solid var(--primary3);
  border-left: 0.5em solid var(--primary1);
}

/* loading spinner */
.loading-overlay {
  z-index: 2147483647;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.loading,
.loading-body {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 60vw;
  height: 60vw;
  margin: -30vw 0 0 -30vw;
  @media screen and (min-width: 30em) {
    width: 30vw;
    height: 30vw;
    margin: -15vw 0 0 -15vw;
  }
  @media screen and (min-width: 60em) {
    width: 10vw;
    height: 10vw;
    margin: -5vw 0 0 -5vw;
  }
}
.loading-body {
  text-align: center;
  top: 45%;
}
.loading {
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color: var(--fo-alt-yellow);
  -webkit-animation: spin 5s linear infinite;
  animation: spin 5s linear infinite;
}
.loading:before {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color: var(--fo-alt-yellow-hilite);
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}
.loading:after {
  content: '';
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 100%;
  border: 3px solid transparent;
  border-top-color: var(--fo-alt-yellow-hilite2);
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

.scroller,
.scroll,
.scroll0,
.scroll0h,
.scroll1,
.scroll1h,
.scroll2,
.scroll2h {
  overflow-y: auto;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track {
    width: 0.5rem;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--fo-frame-overlay-greater);
    border-radius: 0.5rem;
  }
}

.scroll0 {
  height: calc(100vh - 1.7rem);
  max-height: calc(100vh - 1.7rem);
}

/* hr with text in the middle -- hrstrike */
.hrstrike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}
.hrstrike > div {
  position: relative;
  display: inline-block;
}
.hrstrike > div:before,
.hrstrike > div:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 9999px;
  height: 1px;
  background: #999;
}
.hrstrike > div:before {
  right: 100%;
  margin-right: 15px;
}
.hrstrike > div:after {
  left: 100%;
  margin-left: 15px;
}
.transparent {
  color: transparent;
}
.commalist {
  .elem + .elem:last-child {
    &::before {
      content: ' and ';
    }
  }
  .elem:nth-of-type(n + 2):not(:last-child) {
    &::before {
      content: ', ';
    }
  }
}

//
.theme-frame {
  background-color: white;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  // color: var(--t-transbg-light-text);
  box-shadow: 3px 3px 3px #777;
}
label {
  font-weight: bold;
  text-transform: uppercase;
}

.species {
  display: flex;
  align-items: center;
  .species-img {
    text-align: center;
    height: 64px;
    max-width: 64px;
    width: 64px;
    min-width: 64px;
    margin-right: 0.5rem;
    img {
      transition: all 0.5s ease-in-out;
      height: 64px;
      width: 64px;
      &:hover {
        background-color: white;
        height: 128px;
        width: 128px;
        max-width: 128px;
        max-height: 128px;
      }
    }
    z-index: +1;
  }
}
